import * as React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"

export default function Page(props) {
  const { node } = props.data.allContentfulBook.edges[0];
  const amazonButton = props.data.contentfulAsset.fixed.src;
  node.amazonButton = amazonButton;
  return (
    <main>
      <title>Dita Basu - {node.name ? node.name : ""}</title>
       <Layout page="bookdetail" content={node}></Layout>
    </main>
  );
}

export const query= graphql` 
    query($id: String)    {
        allContentfulBook(filter: {id: {eq: $id}}) {
            edges {
                node {
                    name
                    url
                    link
                    cover {
                        fixed(width: 300) {
                            src
                        }
                    }
                    fulltext {
                        raw
                    }
                }
            }
        }
        contentfulAsset(title: {eq: "AmazonButton"}) {
            fixed(width: 149) {
                src
            }
        }
    }
`
                    